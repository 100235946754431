.container {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 90px;
	width: 100%;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.title {
	font-size: 30px;
	font-weight: 500;
}

.actionContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
}