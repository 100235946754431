.container {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 90px;
	width: 100%;
	max-width: 1000px;
}

.logoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.logo {
	height:45px;
}

.title {
	margin-left: 20px;
	font-size: 30px;
	font-weight: 500;
}

.actionContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

}

.loggedUserInfo {
	background: #1A60C6;
	border-radius: 20px;
	padding: 3px;
	padding-left:30px;
	padding-right:30px;
	text-align: center;
	color: white;
	cursor:  pointer;
}