.PageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding:0;
	padding-left: 20px;
	padding-right: 20px;
}

.ButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 500px;
}

.signatureEditBtnContainer {
	height:100px;
	align-items: center;
}

.pageOverride {
	flex: 1;
}


.stepContainer {
	flex: 1;
	width: 100%;

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	overflow: hidden;
}

.stepContainerButtons {
	width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Stepper {
	width: 100%;
	height: 130px;
	align-items: center;
	margin-bottom: 20px;
}

.NeedLoginContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}