
.container {
    position: relative;

}
.imageBackground {
    width: 100%;
}

.imageMuscle {
    transition: opacity 100ms;
    opacity: 0;
    cursor: pointer;
}
.imageMuscleHover {
    opacity: 0.2;
}
.imageMuscleSelected {
    opacity: 1;
}
.imageMuscleSecondarySelected {
    opacity: 0.7;
}