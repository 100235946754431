.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 100%;
    flex: 1;
}

.editorContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    overflow: auto;
}

.containerLeft {
    flex:1;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
    flex-direction: column;
}

.containerRight {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}


