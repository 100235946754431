

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-Light.woff2') format('woff2'),
        url('DINNextLTPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-Italic.woff2') format('woff2'),
        url('DINNextLTPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-Regular.woff2') format('woff2'),
        url('DINNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-Medium.woff2') format('woff2'),
        url('DINNextLTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-Bold.woff2') format('woff2'),
        url('DINNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('DINNextLTPro-BlackCondensed.woff2') format('woff2'),
        url('DINNextLTPro-BlackCondensed.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('DINNextLTPro-Condensed.woff2') format('woff2'),
        url('DINNextLTPro-Condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('DINNextLTPro-MediumCond.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('DINNextLTPro-BoldCondensed.woff2') format('woff2'),
        url('DINNextLTPro-BoldCondensed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('DINNextLTPro-Black.woff2') format('woff2'),
        url('DINNextLTPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}







