.PageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Content {
	display: flex;
	flex-direction: column;
	text-align: center;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.ButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 500px;
}