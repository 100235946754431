

.installContainer {
	padding-top: 30px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.previewContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.previewContainer .preview {
	width: 100%;
}

.previewContainer .actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}


.installInstructionsContainer {
	min-width: 200px;
	max-width: 550px;

	margin-right: 20px;
}